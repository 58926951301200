import {CanActivateFn, Router} from "@angular/router";
import {inject} from "@angular/core";
import {StorageService} from "../service/storage.service";

export const publicProfileGuard: CanActivateFn = (route, state) => {
  if (inject(StorageService).getAuthToken() != null) {
    const router = inject(Router);
    router.navigate(['/profile/' + route.paramMap.get('id')]);
  }
  return inject(StorageService).getAuthToken() == null;
};

export const authProfileGuard: CanActivateFn = (route, state) => {
  const storage = inject(StorageService);
  if (storage.getAuthToken() == null) {
    if (route.params['id']) {
      inject(Router).navigate([`/creator/${route.paramMap.get('id')}`], {
        queryParams: route.queryParams,
      });
    }
  }
  return storage.getAuthToken() != null;
};
