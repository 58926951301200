import {CanActivateFn, Router} from '@angular/router';
import {inject} from "@angular/core";
import {StorageService} from "../service/storage.service";

export const authGuard: CanActivateFn = (route, state) => {
  const storage = inject(StorageService);
  if (storage.getAuthToken() == null) {
    inject(Router).navigate(['/signup'], {
      queryParams: {
        ...route.queryParams,
        returnUrl: state.url,
      },
    });

  }
  return storage.getAuthToken() != null;
};
